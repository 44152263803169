/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import AppContainerView from '../../components/AppContainerView';
import MobileHeader from '../../components/MobileHeader';
import MobileTemplate from '../../components/MobileTemplate';
import * as styles from '../../styles/momStandard/momStandard.module.css';

interface Props {
  onPressRelativeContent?: (routeName: string, param: any) => void;
}
const images: { [key: string]: string } = {
  canadaDefault: '/images/main/standard/canada.png',
  canadaCaution: '/images/main/standard/cautionCanada.png',
  ingredient_bc: '/images/main/standard/bc.png',
  ewg_icon: '/images/main/standard/ewg_icon.png',
  usaDefault: '/images/main/standard/usa.png',
  usaCaution: '/images/main/standard/cautionUsa.png',
  globalGood: '/images/main/standard/global_icon.png',
  globalCaution: '/images/main/standard/detailglobalIngredient.png',
  allergicR: '/images/main/isAllergicRed.png',
  danger: '/images/main/standard/danger.png',
  echaSkinRed: '/images/main/standard/detailechaSkinRed.png',
  echaGrowthRed: '/images/main/standard/echaGrowthRed.png',
  cautionRed: '/images/main/standard/cautionRed.png',
  korea: '/images/main/standard/korea.png',
  ecoGreen: '/images/main/standard/ecoGreen.png',
  europe: '/images/main/standard/europe.png',
  isMc: '/images/main/standard/is_mc.png',
  mom: '/images/main/standard/momg2.png',
  harmness: '/images/main/harmness.png',
};

const defaultData = [
  {
    cautionImg: { name: 'ingredient_bc', width: 42, height: 42 },
    style: {
      top: -5,
    },
    grade: '성분 등급',
    org: 'EWG‘s(미국 비영리 환경연구단체)',
    desc: 'Guide to Healthy Cleaning, Skindeep',
    defaultImg: { name: 'ewg_icon', width: 30, height: 30 },
    relativeContent: {
      title: '연관 콘텐츠 검색',
      routeName: 'TipsDetail',
      param: {
        id: 96,
      },
    },
  },
  {
    cautionImg: { name: 'isMc', width: 42, height: 42 },
    style: {
      top: -5,
    },
    grade: '맘가이드 주의성분',
    org: 'Cosmetic Ingredient Review(CIR), Int. Journal, 원료 회사로부터 data 수집',
    desc: 'Cosmetic Ingredient Review(CIR)의 safety review, 독성학 관련 저널 논문, 원료 합성 방법 등에 관한 데이터 기반 분류',
    defaultImg: { name: 'mom', width: 30, height: 30 },
  },
  {
    cautionImg: { name: 'usaCaution', width: 35, height: 35 },
    grade: '주의 성분',
    org: '미국 환경보호청(EPA)',
    desc: 'Safer Chemical Ingredients List',
    defaultImg: { name: 'usaDefault', width: 30, height: 30 },
  },
  {
    cautionImg: { name: 'canadaCaution', width: 35, height: 35 },
    grade: '주의 성분',
    org: '캐나다 환경부',
    desc: 'Domestic Substances List',
    defaultImg: { name: 'canadaDefault', width: 30, height: 30 },
  },
  {
    cautionImg: { name: 'globalCaution', width: 35, height: 35 },
    grade: '해외 금지 성분',
    org: '미국, 캐나다, 유럽, 국제향료협회 등 화장품 성분 사용 금지 목록',
    desc: '화장품에서의 사용을 전적으로 혹은 특정용도로 금지한 성분',
    defaultImg: { name: 'globalGood', width: 30, height: 30 },
  },
  {
    cautionImg: { name: 'danger', width: 35, height: 35 },
    grade: '고독성\n물질',
    org: 'IARC,ACGIH,EPA,IRIS,EUCLP,NTP,고용노동부 등 공신력 있는 기관의 분류기준',
    desc: '발암성물질/변이원성물질/생식독성물질/내분비계교란물질 중 하나로 알려진 성분',
    defaultImg: { name: 'globalGood', width: 30, height: 30 },
  },
  {
    cautionImg: { name: 'allergicR', width: 50, height: 50 },
    style: {
      width: 35,
      height: 35,
      top: -3,
    },
    grade: '알레르기\n유발성분',
    org: '유럽 연합 집행 위원회 - 과학위원회 (EU - Commission-Scientific Committes)',
    desc: 'Perfume Allergies',
    defaultImg: { name: 'europe', width: 30, height: 30 },
  },
  {
    cautionImg: { name: 'echaGrowthRed', width: 50, height: 50 },
    style: {
      width: 50,
      height: 50,
      left: -8,
      top: -3,
    },
    grade: '독성정보',
    org: '유럽화학물질청(ECHA)',
    desc: 'CLP Regulation, C&L Inventory',
    defaultImg: { name: 'europe', width: 30, height: 30 },
  },
  {
    cautionImg: { name: 'harmness', width: 35, height: 35 },
    grade: '유해성분',
    org: '국립환경과학원고시',
    desc: '화학물질의 분류 및 표시 등에 관한 규정 유해화학물질 리스트',
    defaultImg: { name: 'korea', width: 30, height: 30 },
  },
  {
    cautionImg: { name: 'ecoGreen', width: 35, height: 35 },
    grade: '친환경\n인증',
    org: '한국산업환경기술원',
    desc: '친환경 인증제품 목록',
    defaultImg: { name: 'korea', width: 30, height: 30 },
  },
];

interface Img {
  name: string;
  width: number;
  height: number;
}

interface ListProps {
  index: number;
  org: string;
  desc: string;
  cautionImg: { [key: string]: any };
  defaultImg: Img;
  grade: string;
  length: number;
  style?: { [key: string]: any };
  relativeContent?: { routeName: string; param: any };
  onPressRelativeContent?: (routeName: string, param: any) => void;
}

const ListItem = ({
  index,
  defaultImg,
  grade,
  org,
  desc,
  cautionImg,
  length,
  relativeContent,
  onPressRelativeContent,
  ...props
}: ListProps) => {
  return (
    <div
      className={styles.itemContainer}
      style={{
        borderColor: '#dadada',
        borderBottom: `solid #dadada ${index === length - 1 ? 0.6 : 0}px`,
      }}
    >
      <div className={styles.itemLeft}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span
            style={{
              width: 52,
              fontWeight: 700,
              fontSize: 13,
              letterSpacing: -0.41,
              color: '#000000',
              whiteSpace: 'pre-line',
            }}
          >
            {grade}
          </span>
          <div className={styles.itemStyle1}>
            <img
              style={{
                width: defaultImg.width,
                height: defaultImg.height,
                // imageRendering: "-webkit-optimize-contrast",
                objectFit: 'contain',
              }}
              key={index}
              src={images[defaultImg.name]}
            />
          </div>
        </div>
      </div>

      <div className={styles.itemRight}>
        <span
          style={{
            flex: 1,
            fontWeight: 700,
            fontSize: 11,
            color: '#000000',
            marginBottom: 5,
          }}
        >
          {org}
        </span>
        <span style={{ fontWeight: 400, fontSize: 11, color: '#000000' }}>{desc}</span>
      </div>
    </div>
  );
};

const ListHeaderComponent = () => {
  return (
    <React.Fragment>
      <div className={styles.headerContainer}>
        {/* <div style={styles.headerBlank} /> */}
        <div className={styles.headerBlank}>
          <div className={styles.headerInnerContainer}>
            <img
              style={{
                width: 220,
                height: 77,
                marginLeft: 9,
                marginTop: 16,
                imageRendering: '-webkit-optimize-contrast',
              }}
              src={'/images/main/ic4.png'}
            />
            <div className={styles.headerStyle2}>
              <span className={styles.guide0}>
                맘가이드는 EWG등급만으로는 부족하기에{'\n'}
                <span className={styles.guide1}>국내외 10가지의 화학물질 유해성 데이터</span>
                <span>
                  를{'\n'}통해 성분의 유해성을 판단합니다.{'\n\n'}
                </span>
              </span>
              <span className={styles.guide0}>
                공신력 있는 기관에서 제시한 자료와{'\n'}실질적으로 제품의 유해성으로 연결될 수 있는 데이터만{'\n'}
                보여드리기에, 현존하는 성분분석 서비스 중{'\n'}
                <span className={styles.guide1}>가장 신뢰할 수 있는 서비스입니다.</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
const MomStandard: React.FC<Props> = (props: Props) => {
  return (
    // <_ListHeaderComponent />
    <AppContainerView>
      <MobileTemplate>
        <MobileHeader backgroundColor="#4CDDCB" title="깐깐한 맘가이드 기준" titleStyle={{ color: '#ffffff' }} arrowColor="#ffffff" />
        <div style={{ flex: 1, marginBottom: 10 }}>
          <ListHeaderComponent />
          {defaultData.map((item, index) => {
            return (
              <ListItem
                key={'item_' + index}
                index={index}
                length={defaultData.length}
                {...item}
                onPressRelativeContent={props.onPressRelativeContent}
              />
            );
          })}
        </div>
      </MobileTemplate>
    </AppContainerView>
  );
};

export default MomStandard;
