// extracted by mini-css-extract-plugin
export var container = "momStandard-module--container--6ba19";
export var guide0 = "momStandard-module--guide0--f938d";
export var guide1 = "momStandard-module--guide1--14aa0";
export var headerBlank = "momStandard-module--headerBlank--69dcf";
export var headerContainer = "momStandard-module--headerContainer--42061";
export var headerInnerContainer = "momStandard-module--headerInnerContainer--7ceba";
export var headerStyle2 = "momStandard-module--headerStyle2--82ba1";
export var headerStyle3 = "momStandard-module--headerStyle3--3d1bd";
export var headerStyle4 = "momStandard-module--headerStyle4--7b3b1";
export var itemContainer = "momStandard-module--itemContainer--d7a1c";
export var itemLeft = "momStandard-module--itemLeft--3f128";
export var itemRight = "momStandard-module--itemRight--980bf";
export var itemStyle1 = "momStandard-module--itemStyle1--38c96";
export var itemStyle6 = "momStandard-module--itemStyle6--d3367";